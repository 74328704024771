<template lang="pug">
.try-more(v-if='isTryMore')
  .title(v-html="$TT('tryMore')")
  .desc
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-one')")
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-two')")
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-three')")
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-four')")
  .google_img(@click="download('detailpage_dialog_download')")
    .google
      img(src="../assets/img/google.png")
  .try-later(@click="closeTryMore('detailpage_remove_click')")
    span(v-html="$TT('Try it later')")
</template>
<script>
export default {
  data() {
    return {
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
    }
  },
  computed: {
    // ...mapGetters(["isTryMore"]),
    isTryMore: {
      get() {
        return this.$store.state.isTryMore
      },
      set(newValue) {
        this.$store.state.isTryMore = newValue
      },
    },
  },
  watch: {
    "$store.state.isTryMore"(val) {
      // console.log(val, 'val');
      // this.isTryMore = false
      this.isTryMore = val
    }
  },
  methods: {
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    closeTryMore(eventType) {
      window.gtag('event', eventType)
      window.gtag('event', 'detailpage_dialog_cancel')
      this.$store.commit('isTryMore', false)
    }
  }
}
</script>
<style lang="stylus" scoped>
.try-more
  margin auto
  position absolute
  top 0
  bottom 0
  left 0
  right 0
  width 440px
  height 420px
  background linear-gradient(139.74deg, #FFF5ED -12.64%, #FFFFFF 75.01%)
  box-shadow 0px 3px 30px rgba(132, 145, 165, 0.15)
  border-radius 8px
  .title
    margin 60px 0 20px 53px
    font-family:Roboto-Bold;
    font-size 32px
    line-height 37px
    color #333333
  .desc
    margin 0 0 36px 53px
    .item
      margin-bottom 16px
      font-family Roboto-Regular
      font-size 14px
      line-height 16px
      color #666666
      .img
        width 10px
        height 10px
        display inline-block
        margin-right 8px
  .google_img
    width 189px
    height 56px
    display inline-block
    vertical-align middle
    margin-left 53px
    cursor pointer
    .google
      height 100%
    img
      width 100%
  .try-later
    font-family Roboto-Regular
    display inline-block
    vertical-align middle
    margin-left 36px
    width 100px
    font-size 15px
    line-height 21px
    color #666666
    cursor pointer
</style>